import $2 from "jquery";
window.$2 = $2;
import 'simplebar/dist/simplebar.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick';

"use strict";

let lang = 'pl';

$(function() {
    lang = $('html').attr('lang');

    if ($('.mobile-app').length > 0 && $('.mobile-loader.show').length > 0) {
        setTimeout(function () {
            $('.mobile-loader').removeClass('show');
        }, 3000);
    }

    $('.user-profile a').on('shown.bs.tab', function (event) {
        if ($('.user-profile a.active').closest('li').index() === 1 && $('.throne-edit').hasClass('show')) {
            $('.user-flow-form-container').addClass('px-0');
            $('.user-profile').addClass('fix-inline-padding');
        } else {
            $('.user-flow-form-container').removeClass('px-0');
            $('.user-profile').removeClass('fix-inline-padding');
        }
    });

    $('.added-thrones-table .throne-actions a.throne-details-btn').on('click', function (e) {
        e.preventDefault();
        const self = this;

        $('.user-flow-form-container').removeClass('px-0');

        $('.custom-loader-overlay').removeClass('d-none');

        $.ajax({
            url: $(this).attr('data-url'),
            type: 'GET',
            complete: function (response) {
                const throne = response.responseJSON.throne;
                let throneImages = '';

                $('.custom-loader-overlay').addClass('d-none');

                $('html, body').animate({
                    scrollTop: $('.user-profile').offset().top - 96
                }, 0);

                throne.images.forEach(function (image) {
                    throneImages += `<img src="${image}" alt="" />`;
                });

                let throneParameters = '';

                throne.parameters.forEach(function (parameter) {
                    throneParameters += `<div class="d-flex justify-content-start align-items-center gap-3 pb-3">
                                            <img src="${parameter.icon}" alt="" />
                                            <p>${parameter.name}</p>
                                        </div>`;
                });

                let html = `
                    <i class="bi bi-x-circle" id="close-throne-details"></i>
                    <h2>Szczegóły tronu</h2>
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="d-flex justify-content-start align-items-center">
                            <img src="${throne.logo}" alt="Gdzie jest tron" />
                            <div class="ps-3">
                                <h5 class="throne-name mb-0">${throne.name}</h5>
                                <p class="throne-address mb-0">${throne.address}</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="mt-5">
                        <h6 class="w-100 d-flex justify-content-between align-items-center">Opis tronu <span>Błędny opis?</span></h6>
                        <p>
                            ${throne.description}
                        </p>
                    </div>
                    
                    <div class="throne-images mt-5">
                        ${throneImages}
                    </div>
                    
                    <div class="mt-5">
                        <h6 class="w-100 d-flex justify-content-between align-items-center">Informacje <span>Błędne informacje?</span></h6>
                        
                        <div class="throne-parameters mt-4">
                            ${throneParameters}
                        </div>
                    </div>
                    
                    <div class="buttons d-flex justify-content-between align-items-center mt-5 gap-3">
                        <a href="#throne-delete" class="btn btn-primary btn-lg delete-throne-btn" data-url="${$(self).attr('data-url')}">Usuń tron</a>
                    </div>
                `;

                // <a href="#" class="btn btn-dark btn-lg wide edit-throne">Edytuj tron</a>

                $('.thrones-list').addClass('d-none');
                const element = $(self).attr('href');

                $(element).html(html);

                $(element).addClass('show');
            }
        });
    });

    $('body').on('click', '.delete-throne-btn', function (e) {
        e.preventDefault();

        const self = this;

        $('.user-flow-form-container').removeClass('px-0');

        $('.custom-loader-overlay').removeClass('d-none');

        $.ajax({
            url: $(this).attr('data-url'),
            type: 'GET',
            complete: function (response) {
                $('.custom-loader-overlay').addClass('d-none');

                $('html, body').animate({
                    scrollTop: $('.user-profile').offset().top - 96
                }, 0);

                const throne = response.responseJSON.throne;

                let html = `
                    <h6 class="throne-name mb-0">${throne.name}</h6>
                    <p class="throne-address mb-0">${throne.address}</p>
                    
                    <div class="buttons d-flex justify-content-between align-items-center mt-5 gap-3">
                        <a href="#" class="btn btn-primary btn-lg close-delete-throne">Anuluj</a>
                        <button class="btn btn-dark btn-lg wide delete-throne-confirm-btn" data-url="${throne.deleteUrl}">Potwierdź usunięcie tronu</button>
                    </div>
                `;

                $('.thrones-list').addClass('d-none');
                $('#throne-details').removeClass('show');
                const element = $(self).attr('href');

                $(element).find('.throne-address').html(html);

                $(element).addClass('show');
            }
        });
    });

    $('.throne-edit-btn').on('click', function (e) {
        e.preventDefault();
        const self = this;

        $('.custom-loader-overlay').removeClass('d-none');

        $.ajax({
            url: $(this).attr('data-url'),
            type: 'GET',
            complete: function (response) {
                const throne = response.responseJSON.throne;
                const form = $('#edit-throne-form');

                $('.custom-loader-overlay').addClass('d-none');

                $('html, body').animate({
                    scrollTop: $('.user-profile').offset().top - 96
                }, 0);

                form.prop('action', throne.editUrl);

                if (throne.type === 'owner') {
                    form.find('#throne_form_type_owner').prop('checked', true);
                    $('.for-owner').removeClass('d-none');
                    $('.owner-info').removeClass('d-none');
                    $('.user-info').addClass('d-none');
                } else {
                    form.find('#throne_form_type_user').prop('checked', true);
                    $('.for-owner').addClass('d-none');
                    $('.owner-info').addClass('d-none');
                    $('.user-info').removeClass('d-none');
                }

                form.find('#throne_form_kind').val(throne.kind.id);
                form.find('#throne_form_name').val(throne.name);
                form.find('#throne_form_description').val(throne.description);

                if (Object.keys(throne.openByDays).length > 0) {
                    form.find('#throne_form_hours_misc').prop('checked', true);
                    form.find('#throne_form_hours_misc').closest('.switcher').find('label').addClass('checked');

                    Object.keys(throne.openByDays).forEach(function (day) {

                        form.find(`.day-time-group.${day}`).find('.day-inputs').removeClass('d-none');

                        form.find(`.day-time-group.${day}`).find('.day-inputs').find(`input[name="throne_form[${day}_from]"]`).val(throne.openByDays[day].from);
                        form.find(`.day-time-group.${day}`).find('.day-inputs').find(`input[name="throne_form[${day}_to]"]`).val(throne.openByDays[day].to);

                        form.find(`.day-time-group.${day}`).find('.switcher input').prop('checked', true);
                        form.find(`.day-time-group.${day}`).find('.switcher label').addClass('checked');

                        form.find(`.day-time-group.${day}`).find('.day-closed').addClass('d-none');
                    });

                    form.find('#open-days').prop('disabled', false);
                } else {
                    form.find('.day-time-group').each(function () {
                        $(this).find('.day-inputs').addClass('d-none')
                        $($(this).find('.day-inputs input')[0]).val('');
                        $($(this).find('.day-inputs input')[1]).val('');
                        $(this).find('.switcher input').prop('checked', false);
                        $(this).find('.switcher label').removeClass('checked');
                        $(this).find('.day-closed').removeClass('d-none');
                    });

                    form.find('#open-days').prop('disabled', true);

                    form.find('#throne_form_hours_misc').prop('checked', false);
                    form.find('#throne_form_hours_misc').closest('.switcher').find('label').removeClass('checked');

                    form.find('#throne_form_hours_24h').prop('checked', true);
                    form.find('#throne_form_hours_24h').closest('.switcher').find('label').addClass('checked');
                }

                if (throne.openPeriodicFrom && throne.openPeriodicTo) {
                    form.find('#throne_form_openPeriodic').prop('checked', true);
                    form.find('#throne_form_openPeriodic').closest('.switcher').find('label').addClass('checked');

                    form.find('.open-periodic').prop('disabled', false);

                    form.find('#throne_form_openPeriodicFrom_day').val(throne.openPeriodicFromDay);
                    form.find('#throne_form_openPeriodicFrom_month').val(throne.openPeriodicFromMonth);
                    form.find('#throne_form_openPeriodicFrom_year').val(throne.openPeriodicFromYear);

                    form.find('#throne_form_openPeriodicTo_day').val(throne.openPeriodicToDay);
                    form.find('#throne_form_openPeriodicTo_month').val(throne.openPeriodicToMonth);
                    form.find('#throne_form_openPeriodicTo_year').val(throne.openPeriodicToYear);
                } else {
                    form.find('#throne_form_openPeriodic').prop('checked', false);
                    form.find('#throne_form_openPeriodic').closest('.switcher').find('label').removeClass('checked');

                    form.find('.open-periodic').prop('disabled', true);

                    form.find('#throne_form_openPeriodicFrom_day').val('');
                    form.find('#throne_form_openPeriodicFrom_month').val('');
                    form.find('#throne_form_openPeriodicFrom_year').val('');

                    form.find('#throne_form_openPeriodicTo_day').val('');
                    form.find('#throne_form_openPeriodicTo_month').val('');
                    form.find('#throne_form_openPeriodicTo_year').val('');
                }

                form.find('#throne_form_street').val(throne.street);
                form.find('#throne_form_house').val(throne.house);
                form.find('#throne_form_local').val(throne.local);
                form.find('#throne_form_postal').val(throne.postal);
                form.find('#throne_form_city').val(throne.city);

                if (throne.location) {
                    form.find('#throne_form_marker_lat').val(throne.location.coordinates[0]);
                    form.find('#throne_form_marker_lng').val(throne.location.coordinates[1]);

                    $('body').trigger('show-map');
                }

                form.find('.throne-images .throne-image').each(function () {
                    $(this).find('label').css('background-image', 'none');
                    $(this).find('input').val('');
                    $(this).find('label').attr('data-image', '');
                });

                $('#images-to-delete').val('');

                throne.images.forEach(function (image, key) {
                    $(form.find('.throne-images .throne-image')[key]).find('label').addClass('uploaded');
                    $(form.find('.throne-images .throne-image')[key]).find('label').css({
                        'background-image': `url(${image})`,
                        'background-position': 'center',
                        'background-size': 'cover',
                        'background-repeat': 'no-repeat'
                    });
                    $(form.find('.throne-images .throne-image')[key]).find('label').attr('data-image', image);
                });

                $('.added-images').text(throne.images.length);

                let throneParameters = [];

                throne.parameters.forEach(function (parameter) {
                    throneParameters.push(parameter.id);
                });

                form.find('#throne_form_parameters').find('input[name="throne_form[parameters][]"]').val(throneParameters);

                form.find('#throne_form_nip').val(throne.nip);

                $('.thrones-list').addClass('d-none');
                const element = $(self).attr('href');


                $('.user-flow-form-container').addClass('px-0');
                $('.user-profile').addClass('fix-inline-padding');

                $(element).addClass('show');
            }
        });
    });

    $('#throne-details').on('click', '#close-throne-details', function () {
        $('#throne-details').removeClass('show');
        $('#throne-details').html('');
        $('.thrones-list').removeClass('d-none');

        $('html, body').animate({
            scrollTop: $('.user-profile').offset().top - 96
        }, 0);
    });

    $('#throne-delete').on('click', '.close-delete-throne', function (e) {
        e.preventDefault();
        $('#throne-delete').removeClass('show');
        $('#throne-delete .throne-address').html('');
        $('.thrones-list').removeClass('d-none');

        $('html, body').animate({
            scrollTop: $('.user-profile').offset().top - 96
        }, 0);
    });

    $('#throne-edit').on('click', '#close-throne-edit', function () {
        $('#throne-edit').removeClass('show');

        $('.thrones-list').removeClass('d-none');
        $('.user-profile').removeClass('fix-inline-padding');

        $('.user-flow-form-container').removeClass('px-0');

        $('html, body').animate({
            scrollTop: $('.user-profile').offset().top - 96
        }, 0);
    });

    $('#throne-delete').on('click', '.delete-throne-confirm-btn', function (e) {
        e.preventDefault();
        const url = $(this).attr('data-url');

        $('.custom-loader-overlay').removeClass('d-none');

        $.ajax({
            url: url,
            type: 'DELETE',
            complete: function (response) {
                const throne = response.responseJSON;

                $('.custom-loader-overlay').addClass('d-none');

                $('html, body').animate({
                    scrollTop: $('.user-profile').offset().top - 96
                }, 0);

                $(`.added-thrones-table tbody tr[data-index="${throne.id}"]`).remove();

                $('.deleted-throne-name').text(throne.name);
                $('#throne-details').removeClass('show');
                $('.throne-delete').removeClass('show');
                $('.throne-delete').find('.throne-address').html('');

                $('#confirm-throne-deleted').addClass('show');
            }
        });
    });

    $('#show-thrones').on('click', function () {
        $('.deleted-throne-name').text('');
        $('#confirm-throne-deleted').removeClass('show');
        $('.thrones-list').removeClass('d-none');

        $('html, body').animate({
            scrollTop: $('.user-profile').offset().top - 96
        }, 0);
    });

    $('.info-boxes-slick').slick({
        slidesToShow: 1,
        arrows: false,
        dots: true,
    });

    $('.info-boxes-slick').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        $($('.info-boxes-mobile .info-box')[currentSlide]).removeClass('active');
        $($('.info-boxes-mobile .info-box')[nextSlide]).addClass('active');
    });

    $('.info-boxes-mobile .info-box').on('click', function () {
        $('.info-box').removeClass('active');
        $(this).addClass('active');
        const currentIndex = $(this).index();

        $('.info-boxes-slick').slick('slickGoTo', currentIndex);
    });

    $('.opinions-slider').slick({
        slidesToShow: 4,
        initialSlide: 0,
        slidesToScroll: 1,
        infinite: true,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('.facilities-slider').slick({
        slidesToShow: 4,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('.application-info-slider').slick({
        slidesToShow: 4,
        arrows: false,
        dots: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                }
            },
        ]
    });

    $('.info-boxes-slick').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        $($('.opinions-slider div')[currentSlide]).removeClass('active');
        $($('.opinions-slider div')[nextSlide]).addClass('active');
    });

    $('.slider').each(function () {
        const slidesToShow = $(this).data('slides-to-show');
        const arrows = $(this).data('arrows');
        const dots = $(this).data('dots');

        $(this).slick({
            slidesToShow: slidesToShow,
            arrows: arrows,
            dots: dots,
            draggable: $(this).closest('#workpage').length === 0
        });
    });

    $('.contact-section .btn-submit').on('click', function (e) {
        e.preventDefault();

        const form = $(this).closest('form');
        const formData = form.serializeArray();
        const agree = formData.find(function (item) { return item.name === 'agree' });

        if (agree) {
            agree.value = $('input[name="agree"]').is(':checked')
        } else {
            formData.push({
                name: 'agree',
                value: $('input[name="agree"]').is(':checked')
            });
        }

        if (checkFormValidation(formData)) {
            formData.push(
                {
                    name: 'subject',
                    value: `Kontakt ze strony: ${window.location.href}`
                },
                {
                    name: 'sendTo',
                    value: form.attr('data-email')
                });

            $.ajax({
                url: form.attr('action'),
                type: 'POST',
                data: formData,
                complete: function (response) {
                    if (response.responseJSON.status === 'success') {
                        $(form).prepend('<div class="message-was-sent">Dziękujemy, wiadomość została wysłana.</div>');
                    } else {
                        $(form).prepend('<div class="message-was-not-sent">Przepraszamy. wiadomość nie została wysłana</div>');
                    }

                    formData.forEach(function (field) {
                        if ($(`form [name="${field.name}"]`)) {
                            $(`form [name="${field.name}"]`).removeClass('is-valid');
                            $(`form [name="${field.name}"]`).removeClass('is-invalid');

                            if (response.responseJSON.status === 'success') {
                                if ($(`form [name="${field.name}"]`).attr('type') === 'checkbox') {
                                    $(`form [name="${field.name}"]`).prop('checked', false);
                                } else {
                                    $(`form [name="${field.name}"]`).val('');
                                }
                            }
                        }
                    });
                }
            });
        }
    });

    $('#mobi-app .tutorial .next, #mobi-app .tutorial .prev').on('click', function (e) {
        e.preventDefault();
        const index = $('#mobi-app .tutorial .step.active').index();
        const nextIndex = index + 1;
        const prevIndex = index - 1;
        $('#mobi-app .tutorial .step').removeClass('active');

        $('html, body').animate({
            scrollTop: 0
        }, 0);

        if ($(this).hasClass('next')) {
            $($('#mobi-app .tutorial .step')[nextIndex]).addClass('active');
        } else {
            $($('#mobi-app .tutorial .step')[prevIndex]).addClass('active');
        }

        const currentIndex = $('#mobi-app .tutorial .step.active').index();

        if (currentIndex > 0 && currentIndex < 2) {
            $('button.prev').removeClass('d-none');
            $('button.next').removeClass('d-none').addClass('fix-width');
            $('a.close-tutorial').addClass('d-none');
        } else if (currentIndex === 2) {
            $('button.next').addClass('d-none');
            $('a.close-tutorial').removeClass('d-none');
        } else {
            $('a.close-tutorial').addClass('d-none');
            $('button.prev').addClass('d-none');
            $('button.next').removeClass('fix-width');
        }
    });
});

function checkFormValidation(fields) {
    let isValid = true;
    const regexErrors = {
        pl: {
            email: 'Nieprawidłowy adres e-mail',
            email2: 'Nieprawidłowy adres e-mail',
            telefon: 'Numer telefonu powinien składać się z {range} cyfr',
            imie: 'Imie powinno się składać z samych liter',
            nazwisko: 'Nazwisko powinno się składać z samych liter',
            numberRange: 'Wartość powinna mieścić się w zakresie '
        },
        en: {
            email: 'Incorrect e-mail address',
            email2: 'Incorrect e-mail address',
            telefon: 'The telephone number should consist of {range} digits',
            imie: 'First name should consist of letters',
            nazwisko: 'Surname should consist of letters',
            numberRange: 'The value should be in the range '
        },
        de: {
            email: 'Falsche E-Mail Adresse',
            email2: 'Falsche E-Mail Adresse',
            telefon: 'Die Telefonnummer sollte aus {range} Ziffern bestehen',
            imie: 'Der Vorname sollte aus Buchstaben bestehen',
            nazwisko: 'Der Nachname sollte aus Buchstaben bestehen',
            numberRange: 'Der Wert sollte im Bereich liegen '
        }
    }

    const requiredErrors = {
        pl: 'To pole jest wymagane',
        en: 'This field is required',
        de: 'Dieses Feld ist erforderlich'
    }

    fields.forEach(function (field) {
        const pattern = $('form').find(`[name="${field.name}"]`).attr('pattern');
        let validationMessage = '';

        if (pattern) {
            const regex = new RegExp(pattern);

            if (regex.test(field.value)) {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                if (field.name === 'telefon') {
                    const range = $(`form [name="${field.name}"]`).attr('data-range') || '9';
                    validationMessage = regexErrors[lang][field.name].replace('{range}', range);
                } else {
                    validationMessage = regexErrors[lang][field.name];
                }
            }
        } else {
            if (!field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('required')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= requiredErrors[lang];
            } else if (field.value && $(`form [name="${field.name}"]`).get(0).hasAttribute('min')
                && (parseInt($(`form [name="${field.name}"]`).attr('min'), 10) > parseInt(field.value, 10)
                    || parseInt($(`form [name="${field.name}"]`).attr('max'), 10) < parseInt(field.value, 10))
                && $(`form [name="${field.name}"]`).get(0).hasAttribute('max')) {
                $(`form [name="${field.name}"]`).removeClass('is-valid');
                $(`form [name="${field.name}"]`).addClass('is-invalid');
                isValid = false;
                validationMessage= `${regexErrors[lang]['numberRange']}${$(`form [name="${field.name}"]`).attr('min')} - ${$(`form [name="${field.name}"]`).attr('max')}`;
            } else {
                $(`form [name="${field.name}"]`).removeClass('is-invalid');
                $(`form [name="${field.name}"]`).addClass('is-valid');
                validationMessage = '';
            }
        }

        $(`form [name="${field.name}"]`).closest('.form-group').find('.validation-error').text(validationMessage);
    });

    return isValid;
}
